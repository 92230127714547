import { Routes, Route } from "react-router-dom";
import Layout from "./atoms/Layout/Layout";
import Home from "./screens/Home/Home";
function App() {
  return (
    <Layout>
      <Routes>
        <Route path="/" element={<Home />}></Route>
      </Routes>
    </Layout>
  );
}

export default App;
